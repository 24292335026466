@import '../../styles/breakpoint';

.btn {
  padding: 0.75rem 2rem;
  font-family: var(--body-font) !important;
  color: var(--light);
  background: var(--primary-color);
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  box-shadow: none;
  cursor: pointer;
  outline: none;
  font-weight: 600;

  @include md {
    font-size: 20px;
  }

  &[data-variant='outline'] {
    background-color: transparent;
    color: var(--dark);
    border: 1px solid var(--border-color);
  }
  &[data-variant='stripped'] {
    background-color: transparent;
    color: var(--light);
    border: none;
  }
  &[data-variant='grey'] {
    background-color: var(--grey);
    color: var(--primary-color);
    border: none;
  }
  &[data-size='medium'] {
    padding: 1rem 1.5rem;
    font-size: 0.9rem;
    min-height: auto;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }
}
