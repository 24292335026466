:root {
  /*==== COLORS ====*/
  --primary-color: #ff6c00;
  --primary-light: #fff8f2;
  --yellow-color: #f9dd40;
  --green: #93d2c7;
  --red: #d21c28;
  --dark: #15345b;
  --light: #ffffff;
  --grey: #f4f3f3;
  --off-white: #fbfbfb;
  --base-color: #838595;
  --border-color: #c4c4c4;
  --input-bg: 196, 196, 196;
  --primary-rgba: 255, 108, 0;

  /*===== TYPOGRAPHY ====*/
  --body-font: 'Avenir', san-serif;
  --header-font: 'Angella', san-serif;
  --normal-font-size: 0.9rem;
  --normal-font-weight: 300;

  /*==== SIZES ====*/
  --container-size: 1215px;
  --navbar-height: 110px;
}
