@import "../../styles/breakpoint";
.card {
  width: 328px;
  border-radius: 10px;
  background-color: var(--light);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  grid-column-gap: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.img {
  position: relative;

  .playIcon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  }
}
