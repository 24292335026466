@import "../../../styles/breakpoint";

.layout {
  display: block;

  @include xl {
    display: grid;
    grid-template-rows: auto;
    &.expand {
      grid-template-columns: 19rem 1fr;
    }

    &.collapse {
      grid-template-columns: 10rem 1fr;
    }
  }

  aside {
    position: fixed;
    z-index: 2;
    height: 100vh;
    transition: 0.3s;
    padding: 1rem 2rem;
    z-index: 999;

    @include xl {
      display: block;
      position: sticky;
      transition-property: width;
      transition-duration: 2s;
      top: 0;
    }

    ul {
      margin-top: 1rem;
      li {
        a {
          padding: 0.8rem 2rem;
          color: var(--dark);
          font-weight: 500;

          svg {
            width: 40px;
          }
        }
        span {
          margin-left: 1rem;
          font-weight: 600;
          font-size: 0.9rem;
        }
        &:hover {
          background-color: var(--primary-light);
          border-radius: 10px;

          a {
            color: var(--dark);
          }

          svg {
            path {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .screen {
    overflow-y: scroll;

    .nav {
      position: sticky;
    }
  }

  .mainPadding {
    padding: 1rem;

    @include md {
      padding: 2rem 3rem;
    }
  }
}

.chevron {
  display: none;
  @include xl {
    display: block;
  }
}

.close {
  display: block;
  @include xl {
    display: none;
  }
}

.showMobileNav {
  left: 0;

  @include xl {
    & > div {
      height: auto;
    }
  }
}

.hideMobileNav {
  left: -20rem;

  @include xl {
    left: inherit;
  }
}

.store {
  color: var(--dark);
  font-family: var(--header-font);
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
  padding-bottom: 10px;

  span {
    color: var(--primary-color);
  }
}

.store_desc {
  font-family: var(--body-font);
  color: var(--dark);
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  padding-bottom: 10px;
}

.store_img {
  margin-left: -10px;
  display: grid;
  grid-template-columns: repeat(2, 0.01fr);
}

.app_download {
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
}
