@import "./variables.css";
@import "./functions";
@import "./typography";
@import "./breakpoint";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

*,
::before,
::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: var(--normal-font-size);
  position: relative;
  font-weight: var(--normal-font-weight);
  margin: 0;
  line-height: 1.6;
  background-color: var(--off-white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--header-font) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  max-width: var(--container-size);
  margin: 0 auto;
  overflow: auto;
  padding: 0 1rem;

  @include md {
    padding: 0 2.5rem;
  }
}

.flex {
  display: flex;
  align-items: center;
  height: 100%;
  &.justify-content-between {
    justify-content: space-between;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-start {
    align-items: start;
  }

  &.column {
    flex-direction: column;
  }
}

.vh-100 {
  height: 100vh;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li.activeLink {
  & > a {
    opacity: 100% !important;
    color: var(--dark);
  }
}

.profile-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--border-color);

  &.small {
    height: 36px;
    width: 36px;
  }

  &.medium {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.nav-img {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid var(--border-color);

  &.large {
    min-height: 100%;
    width: 100%;
  }

  &.small {
    height: 36px;
    width: 36px;
  }

  &.medium {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    object-fit: cover;
  }
}

.sticky-top {
  position: sticky;
  top: 0;
}

a {
  text-decoration: none;

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.w-100 {
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;

  label {
    font-weight: 600;
    color: var(--dark);
    p,
    span {
      font-weight: 300;
    }
    span {
      font-size: 80%;
    }
  }

  .prefix-icon {
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
  }

  input,
  select {
    font-family: var(--body-font) !important;
    outline: none;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    padding: 1rem;
    border: none;
    background-color: rgba(var(--input-bg), 0.2);

    &:focus {
      border: 1px solid rgba(var(--primary-rgba), 0.5);
    }

    &[type="checkbox"] {
      height: 1rem;
      width: auto;
      margin: 0;
    }

    &.prefix {
      padding-left: 50px;
    }
  }

  .error {
    color: var(--red);
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.active {
  background-color: var(--primary-color);
  color: var(--light);
  border-radius: 10px;

  span {
    color: var(--light);
  }

  svg {
    path {
      opacity: 1;
      fill: var(--light);
    }
  }
}

.settingActive {
  background-color: var(--primary-color);
  color: var(--light);
  div {
    color: var(--light) !important;
  }
  small {
    color: var(--light) !important;
  }
  svg {
    path {
      opacity: 1;
      fill: var(--light);
    }
  }
}

.chip-active {
  background: var(--dark) !important;
  color: var(--light) !important;
}

.outline-active {
  background: var(--primary-color) !important;
  color: var(--light) !important;
}

.cursor {
  cursor: pointer;
}

.hidden-mobile {
  display: none;
  @include md {
    display: block;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.select {
  position: relative;
}

.select::after {
  content: url(../assets/svg/select-chevron.svg) !important;
  position: absolute !important;
  top: 45% !important;
  right: 5% !important;
  z-index: 1;
  pointer-events: none;
}

//TODO: Change this
// input[type='date']::-webkit-inner-spin-button,
// input[type='date']::-webkit-calendar-picker-indicator {
//   display: none;
//   -webkit-appearance: none;
// }

// .dob {
//   position: relative;
// }

// .dob::after {
//   content: url(../assets/svg/calender.svg) !important;
//   position: absolute !important;
//   top: 40% !important;
//   right: 5% !important;
//   z-index: 1;
// }

.cursor-pointer {
  cursor: pointer;
}

input[type="date"] {
  position: relative;
  padding: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}
input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  content: url(../assets/svg/calender.svg) !important;
  width: 16px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 26px;
  color: #999;
}
.password {
  position: relative;
}

.password {
  .show-password {
    position: absolute;
    top: 43%;
    right: 3%;
    cursor: pointer;
  }
}

.selectedProfile {
  border: 2px solid var(--primary-color);
}

.main-layout {
  position: relative;
}

.loading-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1060;

  &::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1060;
  }

  svg {
    width: 10rem;
    height: 10rem;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: -5px;
    background-color: #ff6c00;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #ff9d54;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #ff6c00;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.bg-primary {
  background: var(--primary-color) !important;
}

.modal {
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 2rem;

    .modal-header {
      .modal-title {
        color: var(--primary-color);
      }
    }

    .btn {
      padding: 0.75rem 1.5rem;
      margin-left: 1rem;
      font-size: 0.9rem;
      height: auto;
    }
  }
}

p {
  margin-bottom: 0;
}

.dashboard-input {
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  border-radius: 5px;
  padding: 1rem;
  font-size: 1rem;
  background: #fff;
}

textarea.dashboard-input {
  resize: none;
  height: 5rem;
}

.dashboard-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.custom-input-group {
  display: flex;

  input {
    border: none;
    outline: none;
    flex-grow: 1;
    margin: 0 1rem;
    background: transparent;
  }

  input:active,
  input:focus {
    border: none;
    outline: none;
  }
}

.back-btn-cover {
  margin: 0 0.5rem 1rem;
}

hr {
  background: rgba(0, 0, 0, 0.25);
}

.btn {
  &.bg-primary {
    color: #ffffff !important;
  }
}

.red-header {
  font-size: large;
  font-weight: 500;
  color: var(--red);
}
