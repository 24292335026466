.badge {
  border-radius: 8px;
  color: var(--primary-color);
  width: 100%;
  padding: 1rem;
  font-weight: 500;
  background: var(--primary-light);
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: rgba(var(--primary-rgba), 0.2);
  }
}
