@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@font-face {
  font-family: 'Angella';
  src: url('../assets/fonts/angella/Angella_demo.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/Avenir-Book.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/Avenir-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url('../assets/fonts/avenir/Avenir-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 70px;
  line-height: 45px;
}

h2 {
  font-size: 40px;
  line-height: 45px;
}

h3 {
  font-size: 30px;
  line-height: 35px;
}

.display-5 {
  font-size: 20px;
}
