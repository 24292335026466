$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);

$sides: (top, bottom, left, right);
$alignment: (left, right, center);
$colors: (
  primary-color,
  yellow-color,
  green,
  dark,
  red,
  light,
  grey,
  border-color,
  primary-light
);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{$space} {
      margin: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}

@each $color in $colors {
  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }
  .text-#{$color} {
    color: var(--#{$color}) !important;
  }
}

@each $align in $alignment {
  .text-#{$align} {
    text-align: #{$align} !important;
  }
}

@for $i from 1 through 10 {
  .opacity-#{$i} {
      opacity: ($i/10);
  }
}
