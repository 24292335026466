@import '../../styles/breakpoint';

.layout {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  padding: 1rem;
  align-items: center;
  border: 1px solid var(--grey);
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--off-white);

  @include md {
    padding: 2rem 3rem;
    grid-template-columns: 2fr 1fr auto;
    position: sticky;
  }

  .info {
    display: none;
    @include xl {
      display: block;
    }
  }

  .hamburger {
    display: block;
    @include xl {
      display: none;
    }
  }

  .notification {
    margin-right: 1rem;
    position: relative;
    margin-top: 10px;

    @include md {
      margin-right: 2.5rem;
    }

    .badge {
      background-color: var(--red);
      border-radius: 14px;
      color: var(--light);
      position: absolute;
      font-size: 12px;
      top: -15px;
      right: -15px;
      height: 28px;
      min-width: 28px;
      padding-top: 4px;
      text-align: center;
    }
  }

  .clearBtn {
    font-size: 12px;
    font-weight: normal !important;
    padding: 0 !important;
    margin: 30px 0 0 0;
    width: 130px;
    height: 0px;
    color: var(--primary-color) !important;
    cursor: pointer;
  }

  .profileImg {
    position: relative;

    .dropdown {
      width: 200px;
      padding: 15px;
      border-radius: 10px;
      position: absolute;
      top: 125%;
      right: 0;
      background: var(--primary-light);
      color: var(--primary-color);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .notificationContainer {
    position: relative;

    .dropdown {
      width: 300px;
      padding: 20px;
      border-radius: 10px;
      position: absolute;
      top: 125%;
      right: 0%;
      background: var(--primary-light);
      color: var(--primary-color);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

      @include md {
        width: 360px;
        right: 50%;
      }

      svg {
        path {
          fill: var(--primary-color) !important;
        }
      }
    }
  }
}
