@import '../../../styles/breakpoint';

.layout {
  background-color: var(--light);
  display: block;
  height: 100vh;


  @include lg {
    display: grid;
    grid-template-columns: 1fr 1rem 1rem 1fr;
    grid-template-rows: auto;
  }

  @include xl {
    display: grid;
    grid-template-columns: 1fr 1rem 1rem 2fr;
    grid-template-rows: auto;
  }

  .aside {
    display: none;
    @include lg {
      display: block;
      overflow: hidden;
    }
    .vector {
      svg:first-child {
        position: absolute;
        top: -10px;
        right: 0px;
      }
      svg:last-child {
        position: absolute;
        bottom: -10px;
        left: -10px;
        z-index: -1;
      }
    }
  }

  .strip {
    height: 0;
    @include lg {
      height: 100vh;
    }
  }

  main {
    height: 100vh;
    overflow-y: auto;
  }

  .formContainer {
    margin: auto;
    width: 100%;

    @include xl {
      width: 70%;
    }

    b {
      font-size: 1.56rem;
    }
  }
}
