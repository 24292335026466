@import "../../styles/breakpoint";

.course-card {
  border-radius: 10px;
  padding: 0.75rem;
  background-color: var(--light);
  margin-bottom: 1rem;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.15);

  .img {
    position: relative;
    margin-bottom: 0.5rem;

    img {
      border-radius: 10px;
      width: 100%;

      &:hover {
        opacity: 0.8;
      }
    }

    .playIcon {
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

  .details {
    margin-top: 1rem;

    .description {
      font-size: 1rem;
      margin: 0.25rem 0 1rem;
      font-weight: 500;
      color: var(--dark);

      @include md {
        width: 80%;
      }
    }

    .tutor-name {
      font-size: 0.75rem;
    }
  }
}
